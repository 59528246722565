
import {ref, SetupContext} from 'vue'
import { useStore } from "vuex";

import {
  IonRow,
  IonGrid,
  IonCol,
  IonContent,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonInput,
  IonButton,
  IonLabel,
} from "@ionic/vue";
export default {
  name: "ModalContent",
  components: {
    IonRow,
    IonGrid,
    IonCol,
    IonInput,
    IonContent,
    IonHeader,
    IonToolbar,
    IonTitle,
    IonButton,
    IonLabel
  },
 
  setup( _props: any,  context: SetupContext) {
    const store = useStore();
    const newProgramCode = ref("")
    const showMessage = ref(false);
    const errMessage = ref("Invalid Code! Please try again.");
    const showInvalidCode = () =>{
      showMessage.value = true;
      setTimeout( ()=> showMessage.value = false, 3000)
    }

    const checkThenSend = async function(code: string){
      //Make Sure Code is valid.
     store.dispatch("axValidateProgramCode", {code}).then( result =>{
       console.log("RESULT ==>", result)
       if(!result){
         showInvalidCode();
       }
       else {
         context.emit("joinProgram", code);
       }
     })
    }
    return {
      newProgramCode,
      checkThenSend,
      showMessage,
      errMessage
    }
  },
  
};
