
import { computed, onMounted, ref } from "vue";
import { useStore } from "vuex";
import {
  IonPage,
  IonContent,
  IonList,
  IonItem,
  IonLabel,
  
  IonHeader,
  IonToolbar,
  IonTitle,
  IonRow,
  IonButton,
  IonCol,
  IonGrid,
  IonModal,
  toastController,
  alertController,
  onIonViewWillLeave,
} from "@ionic/vue";

import JoinDeckModal from "@/components/Modal/JoinDeck.vue";

export default {
  name: "ManageSubscribedDecks",
  components: {
    IonModal,
    JoinDeckModal,
    IonContent,
    IonPage,
    IonList,
    IonItem,
    IonLabel,
    
    IonHeader,
    IonToolbar,
    IonTitle,
    IonRow,
    IonButton,
    IonCol,
    IonGrid, 
   // toastController
  },
  computed: {},
  setup() {
    const store = useStore();
    const contentStyle = store.state.content;
    const menuStyle = store.state.menu;

    const isOpenRef = ref(false);
    const currentUser = computed(() => store.getters.getCurrentUser);
    const activeProgram = computed(() => store.getters.getActiveProgram);
    const userSubscriptions = computed(() => store.getters.getUserSubscriptions);
    const showToastr = async (message: string)=>{
      const toastr = await toastController.create({ position: "top", message, duration:2000, color:"success"});
      toastr.present();
    }

    const deleteSub = (payload: any) =>{
      store.dispatch("axDeleteSubscription", payload);
      store.dispatch('axUserSubscriptions')

    }
      

    const toggleSub = async (payload: any) =>{
      await store.dispatch("axUpdateActiveIndex",{ index: 0})
      await store.dispatch("axToggleSubscription", payload);
      await store.dispatch('axUserSubscriptions');
      await store.dispatch("axSetActiveDeck")
      
      //showToastr("Deck Switched!")
    }
      
    const axCreateSubscription = (payload: any) => {
      store.dispatch("axCreateSubscription", payload).then( ()=> {
         store.dispatch('axUserSubscriptions').then( () => {
          store.dispatch('axSetCurrentUser').then ( ()=>{
            store.dispatch('axSetActiveDeck')
          })
         }).catch(() => {
               showToastr("The code you've entered doesn't match any currently available decks. Please reenter.")
      });
      })
     
   
    }

    // ...mapGetters({
    

    const confirmDeleteSub = async (payload: any) =>{
     const alertC = await alertController.create({header:"Remove subscription?", message:"You will not longer have access.",
      buttons:[
        {text:"Yes", handler: () => deleteSub(payload)},
        {text:"No", handler: () => console.log("ok then")}
        
      ]})
      alertC.present()
    }

    function checkDefaultSubIsInActive() {
      // if user has default sub return false
      const hasCoachPrograms =
        activeProgram.value.filter(({ program }: any) => program.code !== "DEFAULT")
          .length > 0;
      if (hasCoachPrograms) {
        return true;
      }
      return false;
    }

    async function joinProgram(newProgramCode: string) {
      newProgramCode = newProgramCode.toUpperCase()
      if (!newProgramCode.length) return false;
       axCreateSubscription({
        userId: currentUser.value.id,
        firstName: currentUser.value.firstName,
        lastName: currentUser.value.lastName,
        email: currentUser.value.email,
        mobile: currentUser.value.mobile,
        code: newProgramCode,
      });

      
      await store.dispatch("axSetActiveDeck");
      isOpenRef.value = false; 
    }
   
    const setOpen = (state: any) => {
      isOpenRef.value = state;
    };

     onIonViewWillLeave(() => {
      console.log('dec page will leave',activeProgram.value.id );
      toggleSub({programId: activeProgram.value.id})
    });
   

    onMounted(() => {
      store.dispatch('axUserSubscriptions')
    })

    return {
      setOpen,
      isOpenRef,
      joinProgram,
      contentStyle,
      menuStyle,
      checkDefaultSubIsInActive,
      deleteSub,
      confirmDeleteSub,
      toggleSub,
      activeProgram,
      userSubscriptions,
      showToastr
    };
  },
};
